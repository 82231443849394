


.news-list-block{
    padding-top: 80px;
    .news-block-row{
            margin-bottom: 12%;
        .news-block-col{
            h2{
                font-size: 40px;
                font-weight: 300;
                @include media-breakpoint-down(sm){
                    font-size: 30px;
                }
            }
        }
    }
}
// .news-list-a{

// }
.news-item{
    margin-top: 50px;
    margin-bottom: 13%;
    &:hover{
        cursor: pointer;
        .news-wrapper{
            box-shadow: 0 10px 40px 0 rgba(0,0,0,0.15);
            transition: .3s;
            .news{
                h3{
                    display: inline;
                    text-decoration: solid underline $yellow;
                }
                .news-wrapper-a, .link-to-specific-news{
                    &:after{
                        margin-left: 20px !important;
                        transition: .3s;
                    }
                }
            }
        }
    }
    @include media-breakpoint-down(sm){
        margin-bottom: 22%;
    }

    &.odd, &.even{
        .news-wrapper{
            align-self: center;
            background-color: $white;
            margin-left: calc((-100 / 12) * 1%);
            @include media-breakpoint-down(sm){
                margin: 0 auto;
                width: 80%;
                .news{
                    padding: 17px 10px 10px 10px;
                    &::after{
                        content: '';
                        position: absolute;
                        left: -20px !important;
                        background: #f2f2f2;
                        z-index: -1;
                        right: -20px;
                        @include media-breakpoint-down(sm){
                            top: 0;
                            bottom: -20px;
                            right: -20px;
                            left: -20px !important;
                        }
                    }
                }
            }
            .news{
                padding: 60px 40px 35px 40px;
                @include media-breakpoint-down(sm){
                    padding: 17px 10px 10px 10px;
                }
                h3{
                    font-size: 28px;
                    font-weight: 700;
                    line-height: 32px;
                    display: inline;
                    @include media-breakpoint-down(sm){
                        font-size: 20px;
                    }
                }
                p{
                    font-size: 16px;
                    color: $gray;
                    &:nth-last-of-type(1){
                        margin-bottom: 30px;
                    }
                }
                .news-wrapper-a{
                    float: right;
                    text-transform: uppercase;
                    color: $black;
                    font-weight: 600;
                    font-size: 14px;
                    position: relative;
                    padding-right: 36px;
                    &::after{
                        content: url(../img/arrow.svg);
                        margin-left: 10px;
                        position: absolute;
                        top: 3px;
                        width: 18px;
                    }
                    @include media-breakpoint-down(sm){
                        font-size: 14px;
                        font-weight: 500;
                        padding-right: 22px;
                        &::after{
                            content: url(../img/arrow.svg);
                            margin-right: -10px;
                        }
                    }   
                }
                &:after{
                    content: '';
                    position: absolute;
                    top: -20px;
                    right: -20px;
                    bottom: -20px;
                    left: 0;
                    background: $body-background;
                    z-index: -1;
                }
                .news-date{
                    color: $gray;
                    font-size: 14px;
                    font-weight: 300;

                }
                .news-text{
                    display: inline-block;
                    max-height: 200px;
                    overflow: hidden;
                    position: relative;
                    margin-bottom: 10px;
                    color: $gray;
                    &:after{
                        content: '';
                        position: absolute;
                        height: 48px;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
                        background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
                        background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
                        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
                    }
                }
            }
        }
    }
    &.even{
        flex-direction: row-reverse;
        .news-wrapper{
            margin-right: calc((-100 / 12) * 1%);
            @include media-breakpoint-down(sm){

                margin: 0 auto;
                width: 80%;
                .news{
                    padding: 17px 10px 10px 10px;
                    &::after{
                        content: '';
                        position: absolute;
                        left: -20px;
                        background: #f2f2f2;
                        z-index: -1;
                        right: -20px !important;
                    }
                    
                }
            }
            .news{
                &:after{
                    content: '';
                    position: absolute;
                    top: -20px;
                    left: -20px;
                    bottom: -20px;
                    background: #f2f2f2;
                    z-index: -1;
                    right: 0;
                }
            }
        }
    }
    @include media-breakpoint-down(sm){
        margin-top: 30px;
    }
    .image-wrapper{
        .image{
            height: 120%;
            width: 100%;
            margin-top: -8.3%;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
        @include media-breakpoint-down(sm){
            width: 100vw;
            padding: 0;
        }
    }
}
.readmore{
    width: 186px;
    height: 50px;
    border: 1px solid $yellow;
    border-radius: 50px;
    background-color: transparent;
    text-align: center;
    margin: 6% auto;
    display: flex;
    align-items: center;
    justify-content: center;
    @include media-breakpoint-down(sm){
        margin-bottom: 14%;
    }
    a{
        font-size: 16px;
        font-weight: 500;
        color: $black;
        text-transform: uppercase;
    }
}

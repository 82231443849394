.heading-block-title{

    margin-top: 80px;

    h2.heading-block{
        font-size: calc(30px + (40 - 30) * ((100vw - 300px) / (1400 - 300)));
        font-weight: 300;
        &:last-child{
            margin-bottom: 0;
        }
        @media (max-width: 620px){
            font-size: 28px;
            line-height: 42px;
        }
    }
    .rich-text{
        @media (max-width: 620px){
            font-size: 15px;
            line-height: 20px;
        }
    }
}
.purple, .green, .blue{
    width: 100%;
    margin: 0;
    max-width: 100vw;
    max-height: 280px;
    height: 25vh;
    color: $white;
    margin-bottom: 53px;
    @media (max-width: 768px){
        height: 30vh
    }
    .row{
        margin-bottom: 50px;
        max-width: 1140px;
        margin: 0 auto;
        height: 100%;
        align-items: center;
        .heading-col{
            padding: 0;
        }
    }
}
.purple{
    background: linear-gradient(135deg, #8d6db1 0%, #78599B 100%);
}
.green{
    background: linear-gradient(135deg, #8AA975 0%, #6C8F54 100%);
}
.blue{
    background: linear-gradient(135deg, rgb(113, 151, 177) 0%, #4D7A99 100%);
}
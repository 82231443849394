.interest-rate-block{
    background: linear-gradient(135deg, #8AA975 0%, #6C8F54 100%);
    height: 54vh;
    display: flex;
    align-items: center;
    margin-bottom: -80px;
    @include media-breakpoint-down(md){
        height: 100% !important;
    }
    @include media-breakpoint-down(lg){
        height: 63vh;
        padding: 30px;
    }
    .interest-rate-container{
        padding: 40px 0;
        @include media-breakpoint-down(sm){
            padding: 0 15px;
        }
        @media (min-width: 1100px) and (max-width: 1320px){
            max-width: 960px;
        }
        @media (min-width: 768px) and (max-width: 1099px){
            max-width: 900px;
        }
        .interest-rate-row{
            flex-wrap: nowrap;
            justify-content: center;
            margin-left: 30px;

            @include media-breakpoint-down(md){
                flex-direction: column;
                margin-left: -15px;
                align-items: center;
            }
        }
        .interest-rate-col{
            background-color: $white;
            padding: 40px 32px;
            margin-right: 30px;
            &:hover{
                cursor: pointer;
                box-shadow: 0 10px 40px 0 rgba(0,0,0,0.15);
                transition: .3s;
                .title{
                    display: inline;
                    border-bottom: 4px solid $yellow;
                    transition: border-bottom .1s;
                }
                .link{
                    a{
                        &:after{
                            margin-left: 20px !important;
                            transition: .3s;
                        }
                    }
                }
            }
            @include media-breakpoint-down(md){
                margin: 15px 0;
            }
            @media (min-width: 768px) and (max-width: 1320px){
                padding: 26px 22px;
            }
            .title{
                font-size: calc(22px + (28 - 22) * ((100vw - 300px) / (1920 - 300)));
                line-height: calc(26px + (32 - 26) * ((100vw - 300px) / (1920 - 300)));
                font-weight: 700;
                display: inline;
            }
            .description{
                font-size: 18px;
                font-weight: 300;
                line-height: calc(18px + (28 - 18) * ((100vw - 300px) / (1920 - 300)));
                margin: 10px 0 48px 0;
                @media (min-width: 768px) and (max-width: 1320px){
                    margin-bottom: 32px;
                    font-size: 15px;
                }
            }
            .interest-percentage-underline{
                display: flex;
                width: 100%;
                position: relative;
                justify-content: center;
                margin-bottom: 45px;
                .interest-percentage{
                    display: inline;
                    position: relative;
                    padding: 0;
                    .interest-now{
                        font-weight: 300;
                        margin-right: 10px;
                        padding: 0;
                    }
                    &::after{
                        content: '';
                        width: 100%;
                        height: 2px;
                        position: absolute;
                        left: 0;
                        background-color: $yellow;
                        bottom: -28%;
                    }
                }
                .percentage-nr{
                    display: inline;
                    font-size: calc(28px + (44.8 - 28) * ((100vw - 300px) / (1600 - 300)));
                    font-weight: 700;
                    padding: 0;
                    @include media-breakpoint-down(lg){
                        margin-bottom: 34px;
                        position: relative;
                    }
                }
                .percentage{
                    font-size: 32px;
                    padding: 0;
                }
            }
            
            .link{
                max-width: 50%;
                margin: 0 auto;
                text-align: center;
                a{
                    text-transform: uppercase;
                    color: $black;
                    font-size: 14px;
                    font-weight: 500;
                    &::after{
                        content: url(../img/arrow.svg);
                        margin-left: 10px;
                        position: absolute;
                        margin-top: 5px;
                        width: 18px;
                    }
                }
            }
        }
    }
}


    .interest-rate-block.interestcircles{
        background: transparent;
        height: auto;
        margin: 0 0 20px 0 !important;
        .interest-rate-container{
            flex: 0 0 66.66667%;
            max-width: 66.66667%;
            justify-content: center;
            display: flex;
            padding: 40px 0 0 0;
            .interest-rate-row{
                margin: 0;
                flex: none;
                max-width: 100%;
                justify-content: center;
                .interest-rate-col{
                    padding: 40px 0;
                    color: $links;
                    position: relative;
                    flex: 0 0 50%;
                    display: flex;
                    max-width: 36%;
                    min-width: 350px;
                    &:last-child{
                        margin-right: 0;
                    }
                    &:hover{
                    
                        box-shadow: none;
                        transition: none;
                        .title{
                            display: inline-block;
                            border-bottom: none;
                            transition: border-bottom none;
                        }
                    }
                    @media (min-width: 992px) and (max-width: 1200px){
                        min-width: 290px;
                    }
                    @media (max-width: 420px) {
                        margin-bottom: 0;
                        padding-bottom: 0;
                        padding-top: 156px;
                        text-align: center;
                    }
                    .title{
                        width: 100%;
                        display: inline-block;
                    }
                    .link{
                        display: none;
                    }
                    .description{
                        margin: 0;
                        display: inline-block;
                        position: relative;
                        &::after{
                            content: '';
                            width: 100%;
                            height: 2px;
                            position: absolute;
                            left: 0;
                            background-color: #E5AF23;
                            bottom: -35%;
                        }
                    }
                    .interest-date{
                        font-size: 16px;
                        font-weight: 300;
                        margin-top: 14px;
                    }
                    .interest-percentage-underline{
                        display: flex;
                        padding: 0 25px 0 35px;
                        align-items: center;
                        width: 140px;
                        border: 4px solid #E5AF23;
                        border-radius: 100px;
                        height: 140px;
                        @media (max-width: 420px) {
                            right: 30%;
                        }
                        .interest-percentage{
                            &::after{
                                display: none;
                            }
                            .interest-now{
                                display: none;
                            }
                            .percentage{
                                font-weight: 700;
                            }
                        }
                    }
                }
            }
            
        }
    }
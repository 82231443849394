.samthykktir-list-block{
    margin-top: 35px;
    background-color: transparent;
    .question-block{
        &::before{
            content: url(../img/plus.svg);
            width: 20px;
            height: 20px;
            position: absolute;
            margin-top: 25px;
            transition-duration: .5s;
            z-index: -1;
        }
        .question{
            border-top: 1px solid $light-gray;
            padding: 20px 40px 20px 40px;
            font-weight: 500;
            cursor: pointer;
        }
        .answer{
            color: $gray;
            overflow: hidden;
            padding: 30px 30px 0 30px;
            padding-bottom: 0px;
            .rich-text{
                &:not(:first-child) {
                    margin-top: 40px;
                }
                p{
                    color: $dark-gray; 
                }
                ul{
                    li{
                        list-style-type: none;
                        position: relative;
                        &::before {
                            content: '\2022';
                            color: #E5AF23;
                            display: inline-block;
                            font-size: 44px;
                            position: absolute;
                            left: -30px;
                        }
                    }
                }
            }
            .table-container{
                .table{
                    margin-top: 0;
                    margin-bottom: 20px;
                }
            }
            .link-block{
                padding-left: 0;
                .link-row{
                    justify-content: flex-start !important;
                    .link-block-col{
                        width: 100% !important;
                    }
                }
            }
            .container{
                width: 100% !important;
                .row{
                    justify-content: flex-start !important;
                    .file-selector-block{
                        padding-left: 0;
                    }
                }
            }
        }
        &.open{
            &:before{
                content: url(../img/plus.svg);
                width: 20px;
                height: 20px;
                position: absolute;
                margin-top: 25px;
                transform: rotate(45deg);
                transition-duration: .5s;
            }
            .link-block{
                padding-left: 0;
                .link-row{
                    justify-content: flex-start !important;
                }
            }
            .link-block-col{
                width: 100% !important;
            }
            .question{
                border-bottom: 1px solid $yellow;
            }
            .answer{
                padding: 30px;
                padding-bottom: 30px;
                .table-col{
                    flex: 0 0 100%;
                    max-width: 100%;
                }
                .table-container{
                    .table{
                        margin-top: 0;
                        margin-bottom: 20px;
                    }
                }
                .rich-text{
                    h3, h4{
                        font-weight: 700 !important;;
                    }
                    p{
                        a{
                            border-bottom: 2px solid $yellow;
                            color: $black;
                            font-weight: 500;
                        }
                    }
                }
                .container{
                    width: 100% !important;
                    .row{
                        justify-content: flex-start !important;
                        .file-selector-block{
                            padding-left: 0;
                            margin-bottom: 20px;
                            max-width: 100% !important;
                            flex: 100% !important;
                        }
                    }
                }
                .link-block{
                    margin-bottom: 20px;
                    .link-block-col{
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                }
                .chart-block{
                    margin-bottom: 50px;
                }
            }
            .file-selector-block{
                padding-left: 0;
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }
}
.container{        
    .news-headline-row{
        width: 1140px;
        .news-index-headline{
            h2{
                font-size: 48px;
                font-weight: 300;
                margin-top: 11%;
                margin-bottom: 1%;
                @include media-breakpoint-down(sm){
                    font-size: 30px;
                    font-weight: 300;
                    margin-top: 2%;
                    margin-bottom: 14%;
                }
            }
        }
    }
    .news-index-row{
        width: 100%;
    }
    .news-index-col{
        @include media-breakpoint-between(md, lg){
            margin: 0 auto;
        }
    }
    .news-index-item{
        position: relative;
        @include media-breakpoint-down(sm){
            display: unset !important;
        }
        &:hover{
            cursor: pointer;
            .news-wrapper{
                box-shadow: 0 10px 40px 0 rgba(0,0,0,0.15);
                transition: .3s;
                .news{
                    h3{
                        display: inline;
                        text-decoration: solid underline $yellow;
                    }
                    a{
                        &::after{
                            margin-left: 20px !important;
                            transition: .3s;
                        }
                    }
                }
            }
        }
        .news-image-wrapper{
            .image{
                width: 290px;
                height: 290px;
            }
            .news-image-index-page{
                margin-top: 0;
            }
        }
        .news-index-wrapper{
            width: 370px;
            height: 210px;
            background-color: $white;
            padding: 20px;
            position: absolute;
            right: 17px;
            align-self: center;
            @include media-breakpoint-down(sm){
                margin: -25px auto;
                margin-bottom: 90px;
                width: 80%;
                right: 0 !important;
                position: relative;
            }
            @include media-breakpoint-down(md){
                right: 132px;
            }
            
            .index-news{
                height: 150px;
                font-size: 16px;
                line-height: 24px;
                overflow: hidden;
                &::after{
                    content: '';
                    position: absolute;
                    left: 0 !important;
                    background: $body-background;
                    z-index: -1;
                    top: -20px;
                    bottom: -20px;
                    right: -20px;
                    @include media-breakpoint-down(sm){
                        left: -20px !important;
                    }
                }
                h3{
                    font-size: 20px;
                    line-height: 24px;
                }
                
                .news-text{
                    display: inline-block;
                    max-height: 200px;
                    overflow: hidden;
                    position: relative;
                    margin-bottom: 10px;
                    font-weight: 300;
                    font-size: 16px;
                    color: $gray;
                    &:after{
                        content: '';
                        position: absolute;
                        height: 60px;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
                        background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
                        background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
                        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
                    }
                }              
            }
            .news-text-bottom{
                position: absolute;
                bottom: 10px;
                width: 80%;
                font-size: 14px;
                span{
                    font-weight: 300;
                }
                .link-to-specific-news{
                    float: right;
                    text-transform: uppercase;
                    color: $black;
                    font-weight: 500;
                    &::after{
                        content: url(../img/arrow.svg);
                        margin-left: 10px;
                        position: absolute;
                        top: 3px;
                        width: 18px;
                    }
                }
            } 
        }
    }
}
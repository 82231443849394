body.title-hafa-samband{
    .heading-block-title{
        .rich-text{
            font-size: 15px;
        }
    }
}
.form-container{
    form{
        ul{
            padding-left: 0;
            li{
                list-style-type: none;
                font-size: 15px;
                input{
                    border: 1px solid $form-input;
                    width: 571px;
                    height: 46px;
                }
                label{
                    text-align: left;
                    margin-top: 25px;
                    width: 100%;

                }
                textarea{
                    width: 571px;
                    border: 1px solid $form-input;
                    height: 185px;
                    margin-bottom: 30px;
                }
                ul{
                    li label{
                        margin-top: 0;
                    }
                }
            } 
        }

        .form-help{
            max-width: 571px;
        }
        label[for="id_samthykki"]{
            display: none;
        }
        input[type="checkbox"]{
            z-index: 100;
            -webkit-appearance: none;
            border: 1px solid $form-input;
            width: 21px;
            height: 21px;
            display: inline-block;
            position: relative;
            margin-top: 3px;
            margin-bottom: 30px;
            margin-right: 10px;
            float: left;
            &:checked{
                &:after {
                    content: url("../img/check.svg");
                    position: absolute;
                    width: 14px;
                    left: 3px;
                    bottom: -5px;
                }
            }
        } 
        input[type="radio"]{
            width: 20px !important;
            height: 16px !important;
        }
        .submit{
            width: 230px;
            height: 51px;
            border: 1px solid $yellow;
            border-radius: 30px;
            margin: 60px auto;
            margin-left: 180px;
            input{
                font-size: 16px;
                font-weight: 500;
                text-transform: uppercase;
                line-height: 54px;
                background: transparent;
                width: 230px;
                height: 51px;
                border: none;
                margin-top: -4px;
            }
        }
    }
}



@function calculateRem($size) {
    $remSize: $size / 10px;
    @return $remSize * 1rem;
}

@mixin font-size($size) {
    font-size: $size;
    font-size: calculateRem($size);
}

@mixin line-height($size) {
    line-height: $size;
    line-height: calculateRem($size);
}

@mixin font-styles($font-size, $line-height, $letter-spacing, $font-style, $font-weight, $font-family) {
    @include font-size($font-size);
    @include line-height($line-height);
    letter-spacing: $letter-spacing;
    font-style: $font-style;
    font-weight: $font-weight;
    font-family: $font-family;
}
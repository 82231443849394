$white: #fff;
$black: #252525;

$gray-d2: #585858;
$dark-gray: #434343;
$gray: #5B5B5A;
$light-gray: #d8d8d8;

$body-background: #f2f2f2;
$links: #454545;
$form-input: #d2d2d2;

$green: #6C8F54;
$blue: #4D7A99;
$purple: #78599B;
$yellow: #E5AF23
.link-block{
    a{
        font-size: 16px;
        font-weight: 500;
        color: $links;
        &:hover{
            border-bottom: 2px solid $yellow;
        }
        &::after{
            content: url(../img/arrow.svg);
            margin-left: 10px;
            position: absolute;
            margin-top: 5px;
            width: 18px;
        }
    }
}
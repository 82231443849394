
.table-container{
        display: block;
        overflow: auto;
    table{
        margin-top: 40px;
        min-width: 600px;
        thead{
            tr{
                th{
                    border-top: none !important;
                    line-height: 26px;
                    font-size: 18px;
                    padding-bottom: 20px;
                    font-weight: 500;
                }
            }
        }
        tbody{
            tr{
                background-color: transparent;
                height: 60px;
                font-size: 16px;
                &:hover{
                    background-color: rgba(216, 216, 216, 0.3);
                }        
                td{
                    vertical-align: middle !important;
                    padding-right: 2rem !important;
                    line-height: 22px;
                }
            }
        }
    }
}
    
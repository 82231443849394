.image-container{
    .image-wrapper{
        margin-top: 30px;
        img{
            object-fit: cover;
            object-position: center top;
            width: 100%;
            height: 100%;
        }
    }
}
@include media-breakpoint-up(lg) {
    .page-homepage .submenu{
        width: 85%;
        max-width: 1520px;
        margin-left: auto;
        margin-right: auto;
        height: calc(100vh - 300px);
        min-height: 500px;
        max-height: 800px;
        margin-top: 49px;
        margin-bottom: 100px;
        display: flex;
        position: relative;
        @media (min-width: 768px) and (max-width: 1200px) {
            margin-top: 25px;
        }
        .submenu-item{
            display: none; 
        }
        .submenu-item:nth-child(-n+3){
            display: block;
            height: 100%;
            width: 33.33%;
            position: relative;
            overflow: hidden;
            max-height: 670px;
            @include media-breakpoint-between(sm, lg){
                width: 33%;
                max-height: 460px;
            }
            img{
                object-fit: cover;
                object-position: center top;
                width: 100%;
                height: 100%;
            }
            button{
                position: absolute;
                border: none;
                bottom: 100px;
                left: 50%;
                transform: translateX(-50%);
                width: 85%;
                height: 12%;
                font-size: calc(16px + (26 - 16) * ((100vw - 300px) / (1980 - 300)));
                font-weight: bold;
                color: #fff;
                text-transform: uppercase;
                text-align: left;
                padding-left: 48px;
                @include media-breakpoint-between(sm, lg){
                    padding-left: 37px;
                }
                &::after{
                    content: '';
                    border: solid $white;
                    border-width: 0 1.3px 1.3px 0;
                    padding: 7px;
                    transform: rotate(-45deg);
                    -webkit-transform: rotate(-45deg);
                    float: right;
                    margin: 6px 6%;
                }
            }
            &:hover{
                .submenu-wrapper{
                    transform: translateY(0);
                }
                img{
                    filter: blur(10px);
                    transition: filter 0s linear;
                    transition-delay: .2s;
                }
                button{
                    display: none;
                }
            }
            &.color-green{
                .submenu-wrapper:before{
                    background-color: $green;
                }
                button{
                    background-color: $green;
                }
            }
            &.color-blue{
                .submenu-wrapper:before{
                    background-color: $blue;
                }
                button{
                    background-color: $blue;
                }
            }
            &.color-purple{
                .submenu-wrapper:before{
                    background-color: $purple;
                }
                button{
                    background-color: $purple;
                }
            }
        }
        .submenu-wrapper{
            color: #fff;
            padding: 0 0 0 18%;
            padding-top: 163px;
            .title{
                text-transform: uppercase;
                font-weight: bold;
                font-size: 32px;
                margin-bottom: 10px;
            }
            ul{
                line-height: calc(20px + (52 - 20) * ((100vw - 300px) / (1920 - 300)));
                list-style-type: none;
                @include media-breakpoint-between(md, lg){
                    padding-left: 0;
                }
                li{
                    a{
                        color: $white;
                        font-size: 20px;
                        font-weight: 500;
                        margin-left: 1.2vw;
                        &:last-child(6){
                            margin-left: 0px !important;
                        }
                        @media (min-width: 768px) and (max-width: 1441px){
                            font-size: 16px;
                            margin-left: 0;
                        }
                    }
                    &::before{
                        content:'';
                        width: 9%;
                        height: 1.3px;
                        background-color: $white;
                        position: absolute;
                        left: 19%;
                        margin-top: 26px;
                        @media (min-width: 1200px) and (max-width: 1441px){
                            margin-top: 22px;
                        }
                        @media (min-width: 1920px){
                            margin-top: 33px;
                        }

                    }                    
                    @include media-breakpoint-between(md, lg){
                        &::before{
                            display: none;
                        }        
                    }
                }
                .calculator{
                    position: absolute;
                    padding: 0 5%;
                    left: 18%;
                    display: flex;
                    align-items: center;
                    margin-top: 5%;
                    text-transform: uppercase;
                    border: 1px solid rgba(255, 255, 255, 0.25);
                    border-radius: 50px;

                    svg{
                        width: 24px;
                        @media (min-width: 768px) and (max-width: 1441px){
                            width: 16px;
                            margin-right: 12px;
                        }
                    }
                    &::before{
                        display: none;
                    }
                    a{
                        font-size: calc(8px + (20 - 8) * (100vw - 300px) / ((1920 - 300)));
                        @media (min-width: 1920px){
                            margin-left: 2rem;
                            font-size: 20px;
                        }
                    }
                }
            }
        }
        .submenu-item:nth-child(2){
            margin: 0 39px;
            @include media-breakpoint-between(sm, lg){
                margin: 0 20px;
            }
        }
        .submenu-wrapper{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            transform: translateY(100%);
            transition: transform 0.3s ease-in-out;
            &:before{
                content: '';
                position: absolute;
                opacity: 0.9;
                z-index: -1;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
            }
        }
    }

    body:not(.page-homepage) {
        .submenu{
            max-width: 1700px;
            width: 100%;
            margin: 0 auto;
            @include media-breakpoint-down(lg){
                font-size: 15px;
            }
            .submenu-item{
                display: none;
                @media (min-width: 768px) and (max-width: 1280px){
                    padding-left: 0 !important;
                }
                ul{
                    padding: 0 50px 0 60px;
                    margin: 0;
                    align-items: center;
                    height: 65px;
                    @include media-breakpoint-down(lg){
                        height: 56px;
                    }
                    li{
                        font-size: 14px;
                        font-weight: 300;
                        &:first-child{
                            margin-left: 22px !important;
                            @include media-breakpoint-down(lg){
                                margin-left: 12px !important;
                            }
                        }
                        a{
                            text-decoration-line: none;
                            font-weight: 400;
                        }
                    }
                    .active{
                        border-bottom: 2px solid $yellow;
                        padding-bottom: 2px;
                        padding-top: 3px;
                        a{
                            font-weight: 500 !important;
                        }
                        @include media-breakpoint-down(md){
                            border-bottom: none;
                            padding-bottom: 0;
                            padding-top: 0;
                        }
                    }
                    .calculator{
                        border: 1px solid #fff;
                        text-transform: uppercase;
                        display: flex;
                        align-items: center;
                        border-radius: 50px;
                        height: 35px;
                        margin-left: auto !important;
                        font-size: 14px;
                        font-weight: 500;
                        svg{
                            display: none !important;
                        }
                    }
                }
            }
            .submenu-item.active{
                height: 65px;
                img{
                    display: none;
                }
                button{
                    display: none;
                }
                .title{
                    display: none;
                }
                display: block;
                width: 100%;
                    @include media-breakpoint-down(lg){
                        height: 56px;
                    }
                &.color-green{
                    background-color: $green;
                }
                &.color-blue{
                    background-color: $blue;
                }
                &.color-purple{
                    background-color: $purple;
                    @media (min-width: 768px) and (max-width: 1440px){
                        ul{
                            margin-left: -20px;
                        }
                    }
                }
                &.color-gray{
                    background-color: $gray;
                }
                ul{
                    display: flex;
                    list-style: none;
                    li{
                        margin-left: 35px;
                        @include media-breakpoint-down(md){
                            padding-left: 50px !important;
                        }
                        a{
                            color: white;
                        }
                        @include media-breakpoint-down(sm){
                            &:before{
                                content: '';
                                width: 28px;
                                height: 1.3px;
                                background-color: #fff;
                                position: absolute;
                                left: 23%;
                                margin-top: 26px;
                            }
                        }
                        @include media-breakpoint-between(sm, md){
                            &:before{
                                content: '';
                                width: 28px;
                                height: 1.3px;
                                background-color: #fff;
                                position: absolute;
                                left: 20%;
                                margin-top: 26px;
                            }
                        }
                    }
                    .calculator{
                        padding: 0 26px 0 26px;
                        @include media-breakpoint-down(md){
                            margin-top: 20px;
                            align-items: center;
                            display: flex;
                            justify-content: center;
                            padding: 0;
                            height: 50px;
                            margin-left: 20px !important;
                            padding: 0 26px !important;
                            svg{
                                display: inline-block !important;
                                height: 24px;
                                padding-right: 14px;
                            }
                            &::before{
                                display: none;
                            }
                            a{
                                font-size: 18px;
                                font-weight: 500;
                            }
                        }
                    }
                }
            }
        }
    }
}
@include media-breakpoint-down(md) {
    body .nav{
        nav.menu{
            position: static;
            left: 0;
            transform: none!important;
            z-index: auto;
            height: 90px;
            .nav-row{
                z-index: 10;
                position: relative;
            }
            #menu{
                transition: transform .3s ease-in-out;
            }
            &.open.submenu-open{
                #menu{
                    transform: translateX(-100vw);
                }
                &+.submenu{
                    .submenu-item.active{
                        transform: translateX(0);
                        transition: transform .3s ease-in-out;
                    }
                }
            }
            &+.submenu{
                .submenu-item{
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100vh;
                    width: 100vw;
                    padding: 0;
                    transform: translateX(100vw);
                    transition: transform .3s ease-in-out;
                    overflow: scroll;
                    display: block;
                    &.color-green{
                        background-color: $green;
                    }
                    &.color-blue{
                        background-color: $blue;
                    }
                    &.color-purple{
                        background-color: $purple;
                    }
                    button{
                        display: none;
                    }
                    img{
                        display: block;
                        width: 82vw;
                        height: 300px;
                        object-fit: cover;
                        object-position: center top;
                        position: relative;
                        margin-top: 80px;
                        margin-left: 18vw;
                        z-index: -1;
                    }
                    button{
                        color: transparent;
                        width: 18vw;
                        height: 100%;
                        position: fixed;
                        left: 0;
                        top: 0;
                        display: block;
                        background-color: transparent;
                        border: none;
                        z-index: 1;
                    }
                    .submenu-wrapper{
                        margin-top: -45px;
                        width: 80vw;
                        margin-left: 18vw;
                        margin-bottom: 150px;
                        
                        .title{
                            display: block;
                            color: $white;
                            @include font-size(60px);
                            font-weight: 800;
                            text-shadow: 0 10px 30px rgba(0,0,0,0.5);
                            text-transform: uppercase;
                            margin-bottom: 20px;
                            line-height: 79px;
                            margin-left: 16px;
                            width: 90%;
                            word-break: break-word;
                            position: relative;
                            &:before{
                                // content:url('/static/img/arrow_back.svg');
                                position: absolute;
                                top: -18%;
                                left: -25%;
                            }
                        }
                        ul{
                            flex-direction: column;
                            align-items: start;
                            padding-left: 5px;
                            padding-bottom: 100px;
                            li{
                                color: $white;
                                list-style-type: none;
                                margin-left: 16px;
                                position: relative;
                                @include font-size(20px);
                                font-weight: 500;
                                @include line-height(50px);
                                a{
                                    color: $white;	
                                    font-size: 20px;	
                                    font-weight: 500;	
                                    line-height: 50px;
                                    margin-left: 50px;
                                }
                                &::before{
                                    content:'';
                                    width: 27px;
                                    height: 1px;
                                    background-color: $white;
                                    position: absolute;
                                    left: 0;
                                    margin-top: 26px;
            
                                }
                            }
                            .calculator{
                                position: absolute;
                                padding: 0 24px;
                                display: flex;
                                align-items: center;
                                margin-top: 40px;
                                text-transform: uppercase;
                                border: 1px solid #fff;
                                border-radius: 50px;
                                svg{
                                    width: 24px;
                                    @include media-breakpoint-between(sm, lg){
                                        width: 20px;
                                    }
                                }
                                &::before{
                                    display: none;
                                }
                                a{
                                    font-size: 18px;
                                    margin-left: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


@media (min-width: 992px) and (max-width: 1540px) {
    
    body.page-homepage{
        nav{
            .submenu-wrapper{
                padding-top: 93px !important;
            }
        }   
    }
}

body.page-homepage{
    nav{
        .submenu-item{
            .submenu-wrapper{
                li{
                    &:hover{
                        margin-left: 14px;
                        cursor: pointer;
                        &::before{
                            margin-left: 14px;
                        }
                    }
                }
                .calculator{
                    &:hover{
                        cursor: pointer;
                        transition: border .3s ease;
                        border: 1px solid rgba(255, 255, 255, 1);
                        margin-left: 0;
                    }
                }
            }
        }
    }
}
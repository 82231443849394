.color-block-container{
    padding-bottom: 80px;
    .color-block-title{
    margin-top: 60px;
    font-size: calc(20px + (24 - 20) * ((100vw - 300px) / (1600 - 300)));
    }
    .color-block{
        .colorpalette{
            padding-left: 0;
        }
        .text{
            margin-top: 12px;
        }
        .primary{
            display: flex;
            .colorpalette{
                width: 29%;
                margin-right: 12px;
                @include media-breakpoint-down(lg){
                    width: 48%;
                }
                .color{
                    height: 75px;
                }
            }
        }
        .secondary{
            .colorpalette{
                height: 300px;
                float: left;
                margin-right: 1%;
                font-size: 0.85em;
                line-height: 1em;
                margin-bottom: 50px;
                width: 19%;
                @include media-breakpoint-down(lg){
                    width: 30%;
                }
                .color{
                    height: 75px;
                }
            }
        }
    }
}

.pagination{
    margin-bottom: 2rem;
    li{
        margin-right: 10px;
        color: $gray;
        font-weight: 300;
        a{
            &:visited{
                color: $gray;
                font-size: 2rem;
            }
        }
    }
    .active{
        color: $yellow;
        font-size: 2.2rem;
        border-bottom: 1.5px solid $yellow;
    }
}

.mobile-nav-block{
    @media (min-width:560px) and (max-width: 992px) {
        padding-left: 50px;
    }
    .nav-item{
        height: 210px;
        margin-bottom: 20px;
        overflow: hidden;
        position: relative;
        @media (min-width: 560px) and (max-width: 992px){
            width: 75vw;
        }
        img{
            object-fit: cover;
            object-position: top center;
            height: 100%;
            width: 90%;
        }
        &:nth-child(2){
            img{
                width: 85%;
            }
        }
        &:nth-child(3){
            img{
                width: 80%;
            }
        }
        button{
            position: absolute;
            right: 0;
            bottom: 10%;
            width: 240px;
            height: 60px;
            text-transform: uppercase;
            color: $white;
            border: none;
            text-align: left;
            padding: 5px 20px;
            font-size: 20px;
            font-weight: 800;
            opacity: .95;
            &::after{
                content: '';
                border: solid $white;
                border-width: 0 1px 1px 0;
                padding: 7px;
                transform: rotate(-45deg);
                -webkit-transform: rotate(-45deg);
                float: right;
                margin: 6px 6%;
            }
            &.color-blue{
                background-color: $blue;
            }
            &.color-green{
                background-color: $green;
            }
            &.color-purple{
                background-color: $purple;
            }
        }
    }
}
html{
    font-size: 62.5%;
    color: $black;
    a{
        text-decoration: none !important;
        color: $black;
        &:hover{
            color: $black;
        }
    }
    div, button, input{
        &:focus{
            outline: none;
        }
    }
}
body {
    border-right: 30px solid $body-background;
    border-left: 30px solid $body-background;
    border-bottom: 30px solid $body-background;
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @include font-styles(18px, 28px, normal, normal, normal, 'brother-1816');
    @include media-breakpoint-down(md){
        padding-top: 157px;
    }
    @include media-breakpoint-down(lg){
        border-left: 0;
        border-right: 0;
    }
}
.block{
    margin-top: 80px;
    margin-bottom: 80px;
}
@include media-breakpoint-down(md){
    body{
        border-right: none;
        border-left: none;
        border-bottom: none;
        cursor: pointer;
    }
    .container{
        @include media-breakpoint-down(sm){
            padding-left: 24px;
            padding-right: 24px;
        }
        
    }
}
h1{}
h2{}
h3{}
h4{}
h5{}
h6{}
.rich-text{
    font-weight: 300;
    b{
        font-weight: 700 !important;
    }
}

.alert-banner{
    height: 80px; 
    width: 100%; 
    z-index: 10; 
    position: fixed; 
    bottom: 0; 
    left: 0; 
    background-color: #ECC96F;
    padding-top: 18px;

    &:hover{
        background-color: $yellow;
    }
    #closeAlertBanner{
        position: absolute;
        right: 10px;
        top: 10px;
        width: 30px;
        height: 30px;
        cursor: pointer;
    }
    .alert-banner-inner{
        width: 85%;
        max-width: 1520px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 30px;
        padding-right: 30px;
        position: relative;

        svg.alert-icon{
            width: 41px;
            height: 41px;
            margin-right: 15px;
            position: absolute;
            top: 50%;
            left: 15px;
            transform: translateY(-50%);
        }
        .banner-text{
            margin-left: 50px;
            @include media-breakpoint-down(md){
                font-size: 1.4rem;
            }
        }
        .seemore{
            @include media-breakpoint-down(md){
                font-size: 1.4rem;
            }
        }
        span{
            white-space: nowrap;
        }
    }
}
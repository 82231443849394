.news-page-container{
    @include media-breakpoint-down(lg){
        margin-top: 20px;
    }
    .news-page-date{
        font-size: 14px;
        color: $gray;
    }
    .news-page-title{
        font-size: 48px;
        font-weight: 300;
        line-height: 63px;
        @include media-breakpoint-down(sm){
            font-size: 30px;
            line-height: 40px;
            padding-bottom: 20px;
        }
    }
    .news-page-intro{
        .rich-text{
            font-size: 22px;
            font-weight: 300;
            line-height: 30px;
            padding: 20px 0 30px 0;
            opacity: 0.8;
        }
    }
    .news-page-image{
        position: relative;
        margin-top: 30px;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
        &:after{
            content: '';
            background: $body-background;
            position: absolute;
            bottom: -17px;
            z-index: -1;
            left: -17px;
            right: -17px;
            top: 17px;
        }
        figcaption{
            font-size: 14px;
            padding-top: 17px;
            height: 34px;
            font-weight: 300;
            align-items: center;
            display: flex;
            justify-content: center;     
        }
    }
}
.news-page-body{
    margin-top: -40px;
    .rich-text{
        h3{
            font-size: 24px;
            line-height: 30px;
            padding-top: 30px;
        }
    }
}
.file-selector-block{
    position: relative;
    margin-top: 10px;
    a{
        font-weight: 500;
        color: $dark-gray;
        &:hover{
            border-bottom: 2px solid $yellow;
        }
    }
}
.pdf{
    &::before{
        content: url(../img/pdf.svg);
        display: inline-block;
        width: 18px;
        margin-right: 8px;
    }
    span{
        font-weight: 300;
        &::after{
            content: url(../img/arrow.svg);
            margin-left: 10px;
            position: absolute;
            margin-top: 2px;
            width: 18px;
        }
    }
}
.xls, .xlsx{
    &::before{
        content: url(../img/xls.svg);
        display: inline-block;
        width: 18px;
        margin-right: 8px;
    }
}
.doc, .docx{
    &::before{
        content: url(../img/doc.svg);
        display: inline-block;
        width: 18px;
        margin-right: 8px;
    }
}


    .header-block{
        height: calc(100% - 250px);
        margin-bottom: -80px !important;
        min-height: 72vh;
        @include media-breakpoint-down(lg){
            height: calc(100% - 248px);
            margin-bottom: -45px;
        }
        @include media-breakpoint-down(md){
            height: 100%;
        }
        .min-h-72{
            min-height: 72vh;
        }
    }
    .header-content-container{
        align-self: center;
        @include media-breakpoint-down(md){
            order: 2;
        }
        .site-info-container{
            margin: 2% auto;
            width: 60%;
            @media (min-width: 380px) and (max-width: 1440px) {
                width: 78%;
            }
            h1{
                font-size: calc(40px + (48 - 40) * ((100vw - 300px) / (1980 - 300)));
                font-weight: 300;
            }
            .rich-text{
                width: 100%;
                font-size: 18px;
                margin-top: 20px;
                line-height: 28px;
                @include media-breakpoint-between(sm, lg){
                    font-size: 16px;
                    line-height: 22px;
                }
                h3{
                    font-size: 22px;
                    line-height: 30px;
                    font-weight: 300;
                    @include media-breakpoint-between(sm, lg){
                        font-size: 20px;
                        line-height: 24px;
                    }
                }
                ul{
                    li{
                        margin: 16px 0;
                        position: relative;
                        list-style-type: none;
                        line-height: 22px;
                        &::before {
                            content: '\2022';
                            color: #E5AF23;
                            display: inline-block;
                            font-size: 44px;
                            position: absolute;
                            left: -24px;
                        }
                        @include media-breakpoint-between(sm, lg){
                            line-height: 20px;
                        }
                    }
                }
            }
            a{
                font-size: 16px;
                font-weight: 500;
                color: $links;
                text-transform: uppercase;
                &.link-btn{
                    div.document{
                        display: table;
                        min-width: 202px;
                        padding: 10px 30px;
                        border: 1px solid #E5AF23;
                        border-radius: 50px;
                        margin: 30px auto;
                        text-transform: uppercase;
                        text-align: center;
                        &::after{
                            display: none;
                        }
                        @include media-breakpoint-down(lg){
                            padding: 10px 20;
                            text-align: center;
                        }
                    }
                }
                div.document{
                    text-transform:  none;
                    padding: 10px 0;
                    &::after{
                        content: url(../img/arrow.svg);
                        margin-left: 10px;
                        position: absolute;
                        margin-top: 5px;
                        width: 18px;
                    }

                }
                @include media-breakpoint-down(lg){
                    font-size: calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));
                }
            }
            @include media-breakpoint-down(lg){
                margin: 20px auto;
            }
            @include media-breakpoint-down(md){
                padding-top: 16px;
                width: 87vw;
            }
        }
        
    }
    .header-image-container{
        margin-top: -170px;
        z-index: -1;
        min-height: 80vh;
        @include media-breakpoint-down(md){
            min-height: 70vh;
        }
        img{
            width: 50vw;
            height: 100%;
            object-fit: cover;
            position: absolute;
            left: 0;
            @include media-breakpoint-down(md){
                width: 93vw;
                right: 0;
                left: auto;
                max-height: 70vh;
            }
            @include media-breakpoint-down(sm){
                max-height: 70vh;
            }
        }
    }

.video-block{
    .responsive-object{
        position: relative;
        width: 100%;
        padding-bottom: 62.5%;
        iframe{
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }
}
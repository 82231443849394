footer{
    margin-top: 100px;
    background-color: $body-background;
    color: $gray;
    font-size: 14px;
    line-height: 24px;
    max-width: 100vw;
    .footer-logo{
        left: -5%;
        padding-left: 0;
        @include media-breakpoint-down(sm){
            left: 0;
        }
        @include media-breakpoint-up(md){
            left: -6%;
        }
        @include media-breakpoint-up(xl){
            left: -4.6%;
        }
    }
    @include media-breakpoint-down(sm){
        padding: 0 25px;
    }


    .footer-logo-container{
        font-style: italic;
        align-items: flex-end;
        &::after{
            content: '';
            width: 100%;
            height: 1px;
            background-color: $light-gray;
            margin: 15px auto;
            margin-bottom: 37px;
        }
        @include media-breakpoint-down(sm){
            &::after{
                display: none;
            }
        }
        .phrase-footer{
            float: right;
            font-weight: 400;
            .phrase-span{
                position: relative;
                margin-right: 20px;
                &:after{
                    content: '';
                    width: 10px;
                    height: 1px;
                    position: absolute;
                    background-color: #E5AF23;
                    bottom: 37%;
                    right: -15px;
                }
                
            }
        }
    }
}

.footer-container{
    padding: 0 10px;
    margin: 0 auto;
    max-width: calc(300px + (1170 - 300) * ((100vw - 10px) / (1920 - 100)));
    .footer-logo{
        svg{
            height: 43px;
            margin-top: 50px;
        }
        @include media-breakpoint-down(sm){
            padding-left: 15px !important;
        }
    }
    .info-container{
        &::after{
            content: '';
            width: 100%;
            height: 1px;
            background-color: $light-gray;
            margin: 15px auto;
            margin-top: 74px;
            @include media-breakpoint-between(sm, md){
                margin-top: 0;
            }
        }
        @include media-breakpoint-down(sm){
            margin-left: 50px;
            .info-col{
                margin-top: 20px;
                padding: 0;
            }
        }
        @include media-breakpoint-down(sm){
            &::after{
                display: none;
            }
        }
        .info-col{
            width: 18%;
            margin-right: 10px;
            a{
                color: $gray;
            }
            @include media-breakpoint-down(sm){
                width: 100%;
            }
            @include media-breakpoint-between(sm, md){
                width: 26%;
            }
        }
        .last-info-col{
            width: 35%;
            margin-right: auto;
            @include media-breakpoint-down(sm){
                width: 100%;
            }
            @include media-breakpoint-between(sm, md){
                width: 40%;
            }
        }
    }
    .footer-icon-container{
        width: 10%;
        @include media-breakpoint-down(sm){
            width: 70%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            margin-left: -50px;
        }
        @include media-breakpoint-down(md){
            width: 100%;
        }
        .footer-social-icons{
            display: flex;
            align-items: center;
            justify-content: center;
            svg{
                height: 39px;
                margin-right: 15px;
                &:hover{
                    path{
                        fill: $yellow;
                    }
                }
                @include media-breakpoint-down(md){
                    width: 47px;
                    height: 47px;
                }
            }
            @include media-breakpoint-down(md){
                margin: 46px 0 48px 0;
            }
        }
    }
    .bottom-img-row{
        padding-bottom: 30px;
        .bottom-img-col{
            img{
                height: 49px;
                padding-right: 23px; 
            }
            @include media-breakpoint-down(md){
                display: flex;
                justify-content: center;
            }
        }
    }
    
}
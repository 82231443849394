.staff-block-container{
    .staff-block{
        .department-header{
            margin-top: 35px;
            font-weight: 500;
            font-size: 20px;
            @include media-breakpoint-down(sm){
                margin: 35px 0 20px 0;
            }
        }
        .table-container{
            width: 100%;
            display: block;
            overflow: auto;


            .d-table{
                min-width: 674px;
                @include media-breakpoint-down(sm){
                    min-width: 550px;
                }
            }
            .d-table-row{
                height: 60px;
                @include media-breakpoint-down(sm){
                    display: flex !important;
                    flex-direction: column;
                    height: 100px;
                }
                .d-table-cell{
                    font-weight: 300;
                    border-bottom: 1px solid $light-gray;
                    vertical-align: middle;
                    font-size: 16px;
                    width: 35%;
                    @include media-breakpoint-down(sm){
                        border-bottom: none;
                        width: 100%;
                    }
                    &:first-child{
                        font-weight: 500;
                        @include media-breakpoint-down(sm){
                            width: 100%;
                            display: inline !important;
                        }
                    }
                    &:last-child{
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        height: 60px;
                        text-align: right;
                        @include media-breakpoint-down(sm){
                            height: auto !important;
                            display: inline !important;
                            width: 100%;
                            text-align: left;
                            text-decoration: solid underline #E5AF23;
                            -webkit-text-decoration: solid underline #E5AF23;
                        }
                    }
                    .email-icon{
                        margin-left: 8px;
                        svg{
                            width: 18px;
                            path{
                                fill: $yellow;
                            }
                            polygon{
                                fill: $yellow;
                            }
                        }
                    }
                }
            }
        }
    }
}


.page-standardsubpage{
    .site-info-container{
        max-width: 1570px;
        width: 81.77vw;
        margin-left: auto;
        margin-right: auto;
        h1{
            font-size: calc(36px + (72 - 36) * ((100vw - 414px) / (1920 - 414)))!important;
            margin-top: calc(0px + (120) * ((100vw - 414px) / (1920 - 414)));
            margin-bottom: calc(30px + (40 - 30) * ((100vw - 414px) / (1920 - 414)));
        }
    }
}
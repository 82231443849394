body.page-homepage{
    padding-top: 105px;
    nav.menu{
        position: fixed;
        height: 105px;
        z-index: 10;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100vw - 100px);
        @include media-breakpoint-down(md){
            transform: translateX(-53%);
            padding-top: 12px;
            height: 80px;
        }
        @media (max-width: 1200px){
            width: calc(100vw - 60px);
        }
        .nav-row{
            margin-top: 4px !important;
        }
    }
}
body:not(.page-homepage){
    nav.menu.open{
        @include media-breakpoint-down(md){
            transform: translateX(0%);
        }
    }
    &.sticky-nav{
        padding-top: 157px;
        header > .nav{
            position: fixed;
            width: calc(100vw - 100px);
            top: -105px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 10;
            @include media-breakpoint-down(md){
                width: 100% !important;
                top: 0;
            }
            .menu{
                margin-top: 0;
                transition: margin-top .3s ease-in-out;
                @include media-breakpoint-down(md){
                    transition: none;
                }
            }
            nav.menu.open{
                @include media-breakpoint-down(md){
                    transform: translateX(0%);
                }
            }
        }
        &.sticky-nav-open{
            header > .nav .menu{
                margin-top: 105px;
                @include media-breakpoint-down(md){
                    margin-top: 0;
                }
            }
        }
    }
}

    
   .menu.invisible {
    opacity: 0;
    top: -1000% !important;
   }
    
   .et-fixed-header.menu {
    opacity: 0;
   }
    
   .menu.detached {
    position: fixed !important;
    top: 0;
    opacity: 1 !important;
   }
@include media-breakpoint-down(md){
    .uppercase{
        text-transform: uppercase;
    }
    .nav{
        padding: 0;
        justify-content: flex-start;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1030;
        @include media-breakpoint-down(sm){
            justify-content: flex-start;
        }
        .logo-container-fluid{
            padding: 0 15px;
        }
        .lock-icon{ 
            a{
                float: right;
            }
            svg{
                width: 17px;
                height: 23px;
                z-index: 9999;

            }
        }
        .logo-container{
            margin-left: 20px;
            max-width: 100% !important;
            @include media-breakpoint-up(lg){
                width: 30%;
            }
        }
        .menu-column{
            order: 1;
        }
        .search-icon{
            display: none;
        }
        nav.menu.open{
            height: 100vh;
            background-color: $dark-gray;
            width: 100vw !important;
            transform: translateX(-50%);
            .nav-row{
                margin-left: 0;

                .navbar-nav{
                    overflow: scroll;
                    height: calc(100vh - 80px);
                    padding-bottom: 10px;
                    margin-top: 132px;
                }
            }
            .logo-container{
                margin-left: 0
            }
            .line {
                background-color: $white !important;
                display: block;
                margin: 5px 0;
                height: 2px;
                width: 23px;
                transition: all 0.1s ease-in;
                &:nth-child(1) {
                    transform: translateY(7px) rotate(45deg);
                }
                
                &:nth-child(2) {
                    transform: translateY(-3px) rotate(-45deg);
                }
            }
            
            .lock-icon svg{
                path{
                    fill: $white;
                    transition: 0.2s ease-in-out;
                }
            }
            .logo-container svg{
                path{
                    fill: $white;
                    transition: 0.2s ease-in-out;
                }
            }
            li {
                transform: translateX(0vw);
                padding-left: 15px;
                background-color: $white;
                display: flex;
                transition-delay: 0.0s;
                transition: transform 0.25s ease-in-out;
                &:nth-of-type(1) {
                    background-color: $purple;
                }
                &:nth-of-type(2) {
                    transition-delay: 0.05s;
                    width: calc( 100% - 80px );
                    background-color: $blue;
                }
            
                &:nth-of-type(3) {
                    transition-delay: 0.1s;
                    width: calc( 100% - 100px );
                    background-color: $green;
                }
            
                &:nth-of-type(4) {
                    transition-delay: 0.15s;
                    width: calc( 100% - 120px );
                    background-color: $white;
                    a{
                        font-size: 16px;
                        color: $gray;
                        &::after{
                            border: solid $gray;
                            border-width: 0 2px 2px 0;
                        }
                    }
                }
                &:nth-of-type(5) {
                    transition-delay: 0.2s;
                    width: calc( 100% - 140px );
                    background-color: $white;
                    a{
                        font-size: 16px;
                        color: $gray;
                        &::after{
                            border: solid $gray;
                            border-width: 0 2px 2px 0;
                        }
                    }
                }
                &:nth-of-type(6) {
                    transition-delay: 0.25s;
                    width: calc( 100% - 160px );
                    background-color: $white;
                    a{
                        font-size: 16px;
                        color: $gray;
                        &::after{
                            border: solid $gray;
                            border-width: 0 2px 2px 0;
                        }
                    }
                }
                &:nth-of-type(7) {
                    transition-delay: 0.3s;
                    width: calc( 100% - 180px );
                    background-color: $white;
                    a{
                        font-size: 16px;
                        color: $gray;
                        &::after{
                            border: solid $gray;
                            border-width: 0 2px 2px 0;
                        }
                    }
                }
                &:nth-of-type(8){
                    transition-delay: 0.35s;
                }
                a{
                    width: 100%;
                }
            }
            .search {
                padding: 0;
                margin-top: 10px;
                background-color: transparent;
                width: 90vw;
                
                .search-wrapper{
                    position: relative;
                    width: 95%;
                    &:placeholder-shown{
                        padding-left: 12px;
                    }
                }
                
                .sr-only{
                    position: absolute;
                    left: -99999px;
                    height: 1px;
                    width: 1px;
                    overflow: hidden;
                }
                input{
                    -webkit-appearance: none;
                    -webkit-box-shadow: 0 0 25px 0 rgba(255,255,255,0.25);
                    background-color: $dark-gray;
                    width: 100%;
                    margin-top: 0;
                    height: 60px;
                    border: none;
                    padding-left: 12px;
                    color: $white;
                }

                svg{
                    height: 25px;
                    margin-right: 18px;
                    position: absolute;
                    right: 4%;
                    top: 28%;
                    @include media-breakpoint-down(md){
                        z-index: 1;
                        right: 10%;
                        top: 30%;
                    }
                    @include media-breakpoint-down(lg){
                        margin-right: 0;
                    }
                    path{
                        fill: $white;
                    }
                }
            }
            .mobile-search-open{
                position: fixed;
                top: 80px;
                height: 90vh!important;
                background-color: $dark-gray;
                margin-left: 0;
                width: 100vw;
                .search-wrapper{
                    input{
                        position: fixed;
                        top: -18px;
                        right: -11px;
                        &::placeholder{
                            color: $white;
                            -webkit-color: $white;
                        }
                    }
                }
                svg{
                    opacity: 0;
                }
                .close-button.mobile {
                    position: absolute;
                    height: 40px;
                    width: 40px;
                    right: 40px;
                    z-index: 5;
                    top: -8px;
                    padding: 10px;
                    cursor: pointer;
                    span {
                        width: 100%;
                        height: 2px;
                        border-radius: 2px;
                        background-color: #E5AF23;
                        display: block;
                        left: 0px;
                        position: relative;
                        &:nth-of-type(1) {
                            transform: rotate(45deg);
                            top: 9px;
                        }
                        &:nth-of-type(2) {
                            transform: rotate(-45deg);
                            top: 7px;
                        }
                    }
                }
                .results-container{
                    height: 100vh;
                    position: fixed;
                    top: 80px;
                    padding: 24px;
                    overflow: scroll;
                    .close-button {
                        display: block;
                        position: absolute;
                        height: 20px;
                        width: 20px;
                        right: 50px;
                        z-index: 5;
                        top: 0;
                        @include media-breakpoint-down(lg) {
                            display: none;
                        }
                        span {
                            width: 100%;
                            height: 2px;
                            border-radius: 2px;
                            background-color: $yellow;
                            display: block;
                            transform: rotate(45deg);
                            top: 9px;
                            left: 0px;
                            position: relative;
                            &:nth-child(2) {
                                transform: rotate(-45deg);
                                top: 7px;
                            }
                        }
                    }
                    .search-results{
                        a{
                            &::after{
                                display: none;
                            }
                        }
                        .result{
                            margin-bottom: 40px;
                            position: relative;
                            &::after{
                                content: ' ';
                                width: 90vw;
                                position: absolute;
                                border-bottom: 1px dotted rgba(255, 255, 255, 0.25);
                                bottom: -20px;
                            }
                            h3{
                                font-size: 22px;
                            }
                            p{
                                font-size: 15px;
                                line-height: 20px;
                                font-weight: 400;
                            }
                            .result-read-more-btn{
                                margin-top: 20px;
                                &::after{
                                    content: '';
                                    position: absolute;
                                    border: solid $white;
                                    border-width: 0 2px 2px 0;
                                    padding: 7px;
                                    transform: rotate(-45deg);
                                    -webkit-transform: rotate(-45deg);
                                    margin: 6px 22px;
                                    right: 0;
                                }
                                a{
                                    font-size: 16px;
                                    border-bottom: 2px solid $yellow;
                                    position: relative;
                                }
                            }
                        }
                    }
                }
                .search-results-container{
                    padding-bottom: 200px;
                }
            }
        }
        .nav-row{
            flex-wrap: nowrap;
            align-items: center;
            flex-wrap: nowrap;
            margin-top: 18px;
            .logo-container{
                svg{
                    height: 43px;
                }
                
            }
            .menu-column{
                margin: 10px 10px 0 15px;
                .hamburger {
                    border: 0;
                    background-color: transparent;
                
                    .line {
                        background-color: $gray;
                        display: block;
                        margin: 5px 0;
                        height: 2px;
                        width: 23px;
                        transition: all 0.1s ease-in;
                        &:nth-child(2) {
                            margin-top: 8px;
                        }
                    }
        
                }
            }
            
        }

        .menu {
            width: 95%;
            height: calc(80px + (105 - 80) *((100vw - 300px) / (1980 - 300)));
            background-color: #FFFFFF;
            box-shadow: 0 5px 30px 0 rgba(0,0,0,0.1);
            @include media-breakpoint-down(md){
                width: 95% !important;
            }
                ul {
                padding: 0;
                margin: 0;
                list-style-type: none;
                position: fixed;
                top: -100vh;
                left: 0;
                width: 100vw;
                flex-direction: column;
                top: 0;
                margin-top: 100px;
                height: 0;

                li {
                    height: 60px !important;
                    margin: 6px 0;
                    display: flex;
                    align-items: center;
                    height: 100vh;
                    width: 20vw;
                    transform: translateX(100vw);
                    width: calc(100vw - 60px) ;
                    margin-left: 30px;
                        
                    a{
                        color: $white;	
                        font-size: 20px;	
                        font-weight: bold;	
                        letter-spacing: 0.18px;	
                        &::after{
                            content: '';
                            border: solid $white;
                            border-width: 0 2px 2px 0;
                            padding: 7px;
                            transform: rotate(-45deg);
                            -webkit-transform: rotate(-45deg);
                            float: right;
                            margin: 6px 22px;
                        }
                    }

                }
            }
        }
     
    }
}

@include media-breakpoint-up(lg) {
    .nav{
        padding: 0;
        width: calc(100vw - 100px);
            .logo-container{
                flex-grow: 0 !important;
        }
        .menu{
            height: 94px;
            background-color: #FFFFFF;
            box-shadow: 0 5px 30px 0 rgba(0,0,0,0.1);
            max-width: 1700px;
            margin: 0 auto;
            @include media-breakpoint-down(lg){
                height: 95px;
            }
        }
        nav{
            width: 100%;
            align-items: center;
            display: flex;

        }
        .nav-row{
            margin: 0;
            align-items: center;
            margin-top: 12px;
            .logo-container{
                margin: 0 18px 0 20px;
                width: 10%;
                z-index: 1;
                svg{
                    width: 142px;
                    @include media-breakpoint-between(sm, lg){
                        width: 116px;
                    }
                }
            }
            .search{
                max-width: 30px;
                padding: 0;
                margin-left: 30px;
                cursor: pointer;
                margin-right: calc(-26px + (22 + 26) * (100vw - 300px) / ((1920 - 300)));
                svg{
                    height: 30px;
                    margin-top: 4px;
                    margin-left: 20px;
                }
            }
            .lock-icon{
                max-width: 22px;
                padding: 0;
                margin-left: 60px!important;
                margin-right: 10px;
                position: relative;
                cursor: pointer;
                svg{
                    width: 21px;   
                    height: 29px;
                    position: absolute;
                    left: -14px;
                    top: 6px;
                }
                &.mypages-open{
                    .mypages-wrapper{
                        width: 45vw;
                        margin-top: 2px;
                        height: 80px;
                        margin-right: -8px;
                        top: -22px;
                        display: flex;
                        justify-content: flex-end;
                        transition: width .5s ease-in-out;
                        @media (min-width: 350px) and (max-width: 1360px){
                            width: 80vw;
                        }
                        .launagreidendavefur-btn{
                            margin-right: 12px;
                        }
                    }
                    svg{
                        position: absolute;
                        left: -14px;
                        top: 6px;
                    }
                }
                .mypages-btns{
                    margin-left: 50px;
                    position: relative;
                    svg{
                        position: absolute;
                        width: 14px;
                        left: -22px;
                        top: -1px;
                    }
                    a{
                        white-space: nowrap;
                    }
                }
                .mypages-wrapper{
                    position: absolute;
                    display: flex;
                    justify-content: flex-end;
                    right: 0;
                    width: 0;
                    height: 80px;
                    top: -22px;
                    overflow: hidden;
                    background-color: $white;
                    transition: width .5s ease-in-out;
                    z-index: 0;
                    margin-top: 2px;
                    margin-right: -8px;
                    align-items: center;
                    .launagreidendavefur-btn{
                        margin-right: 12px;
                        @media (max-width: 860px){
                            margin-right: 0;
                        }
                    }
                    .inner-container{
                        border-radius: 25px;
                        border: 1px solid $yellow;
                        height: 50px;
                        padding-left: 14px;
                        background-color: $white;
                        padding-right: 40px;
                        .close-icon{
                            svg{
                                position: relative;
                                margin-left: 14px;
                                width: 26px;
                                height: 26px;
                                top: 0;
                            }
                        }
                    }
                }
            }
            .menu-column{
                width: 84%;
                .navbar-nav{
                    @include media-breakpoint-between(sm, lg){
                        margin-left: 42px;
                    }
                }
                li{
                    position: relative;
                    &:nth-last-child(-n + 6){
                        margin-left: 14px;
                        a{
                            font-size: 16px;
                            @include media-breakpoint-between(sm, lg){
                                font-size: 15px;
                            }
                        }
                    }
                    a{
                        color: $gray;	
                        font-size: 19px;
                        font-weight: 400;
                        @include media-breakpoint-between(sm, lg){
                            font-size: 16px;
                        }
                    }
                    &.active{
                        a{
                            font-weight: 500;
                        }
                        &.color-green a{
                            color: $green;
                        } 
                        &.color-blue a{
                            color: $blue;
                        } 
                        &.color-purple a{
                            color: $purple;
                        } 
                        &:before{
                            content: '';
                            position: absolute;
                            bottom: -22px;
                            left: 50%;
                            transform: translateX(-50%);
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-width: 0 10px 10px 10px;
                            border-color: transparent transparent $gray transparent;
                            @include media-breakpoint-down(lg){
                                content: '';
                                position: absolute;
                                bottom: -23px;
                                left: 50%;
                                transform: translateX(-50%);
                                width: 0;
                                height: 0;
                                border-style: solid;
                                border-width: 0 10px 10px 10px;
                                border-color: transparent transparent $gray transparent;
                            }
                        }  
                        &.color-green{
                            &:before{
                                border-color: transparent transparent $green transparent; 
                            }
                        }
                        &.color-blue{
                            &:before{
                                border-color: transparent transparent $blue transparent; 
                            }
                        }
                        &.color-purple{
                            &:before{
                                border-color: transparent transparent $purple transparent; 
                            }
                        }
                    }
                }
                .uppercase{
                    margin-left: 40px;
                    text-transform: uppercase;
                    
                    @include media-breakpoint-between(sm, lg){
                        margin-left: 12px;
                    }
                    &:nth-last-child(-n + 5){
                        margin-right: 24px;
                        @include media-breakpoint-between(sm, lg){
                            margin-left: 6px;
                        }
                    }
                }
                .english-button{
                    font-size: 16px;
                    margin-left: 30px;
                }
                .search{
                    &.search-open{
                        margin-right: 0;
                        .search-wrapper{
                            width: 42vw;
                            margin-top: -2px;
                            margin-right: 0;
                            background-color: #fff;
                            z-index: 2;
                            input{
                                background-color: #fff;
                                
                            }
                            .search-results-container{
                                padding: 0 62px;
                                display: none;
                                &.show{
                                    display: block !important;
                                }
                                .close-button {
                                    position: absolute;
                                    height: 20px;
                                    width: 20px;
                                    right: 60px;
                                    z-index: 5;
                                    margin-top: 20px;
                                    span {
                                        width: 100%;
                                        height: 2px;
                                        border-radius: 2px;
                                        background-color: $yellow;
                                        display: block;
                                        transform: rotate(45deg);
                                        top: 9px;
                                        left: 0px;
                                        position: relative;
                                        &:nth-child(2) {
                                            transform: rotate(-45deg);
                                            top: 7px;
                                        }
                                    }
                                }
                                .search-results{
                                    overflow: scroll;   
                                    .result{
                                        border-bottom: 1px solid #ece9e9;
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: center;
                                        color: $dark-gray;
                                        padding: 20px 0;
                                        &:first-child{
                                            margin-top: 50px;
                                        }
                                        h3{
                                            font-size: 20px;
                                        }
                                        .result-read-more-btn{
                                            a{
                                                text-decoration: solid underline $yellow !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .on-focus{
                            .results-container{
                                background-color: #fcfbfb;
                                padding-top: 1px;
                                height: 90vh;
                                overflow: scroll;
                                .search-results-container{
                                    box-shadow: inset 0px 30px 30px -30px rgba(0, 0, 0, 0.1);
                                }
                            }
                        }
                        svg{
                            position: absolute;
                            margin-top: 6px;
                            margin-left: -20px;
                            z-index: 3;
                        }
                    }
                    .search-wrapper{
                        position: absolute;
                        right: 0;
                        width: 0;
                        overflow: hidden;
                        background-color: $white;
                        transition: width .3s ease-in-out;
                        z-index: 0;
                        margin-top: -30px;
                        margin-right: 30px;
                        @include media-breakpoint-down(lg){
                            width: calc( 100% - 160px);
                        }

                        input{
                            width: 100%;
                            left: 0;
                            background: transparent;
                            border-bottom: 1px solid $yellow !important;
                            border: none;
                            padding: 5px 10px;
                        }
                    }
                }
            }
        }
    }
    .remove-container{
        display: none;
    }
    #menu{
        .sub-menu{
            width: 100%;
            position: absolute;
            left: 0;
        }
    }
}

@media (max-width: 1280px){
    .lock-icon{
        max-width: 20px;
        padding: 0;
        margin-left: 14px;
        position: relative;
        cursor: pointer;
        svg{
            width: 14px;   
            height: 20px;
            position: absolute;
            left: -14px;
            top: -11px;
        }
        &.mypages-open{
            .lock-icon-btn{
                display: none;
            }
            .mypages-wrapper{
                width: 65vw;
                font-size: 15px;
                height: 30px;
                margin-top: -11px;
                @media(max-width: 860px){
                    width: 100vw;
                    height: 326px;
                    justify-content: center;
                    height: 414px;
                    z-index: 1;
                    background-color: $yellow;
                    margin: 44px 0 0 0;
                    flex-direction: column;
                    align-items: center;
                    position: fixed;
                    transition: width .3s ease-in-out;
                }
                .mypages-btns{
                    margin-left: 50px;
                    position: relative;
                    @media (max-width: 860px){
                        svg{
                            display: none;
                        }
                        position: relative;
                        background-color: #fff;
                        height: 61px;
                        width: 80vw;
                        display: flex;
                        align-items: center;
                        padding-left: 20px;
                        margin: 0 0 19px 0 !important;
                        &:after{
                            content: url(../img/Lock.svg);
                            position: absolute;
                            right: 14px;
                            width: 17px;
                        }
                        a{
                            font-size: 15px;
                            text-transform: uppercase;
                            font-weight: 500;
                        }                
                    }
                }
            }
            svg{
                position: absolute;
                left: -14px;
                top: 6px;
            }
        }
        .mypages-btns{
            margin-left: 50px;
            svg{
                position: absolute;
                position: absolute;
                margin-top: 8px;
                left: -30px;
                top: -7px;
                width: 15px;
            }
        }
        .mypages-wrapper{
            position: absolute;
            display: flex;
            justify-content: flex-end;
            right: 0;
            width: 0;
            height: 50px;
            overflow: hidden;
            background-color: $white;
            z-index: 0;
            margin-top: 2px;
            margin-right: 30px;
        }
    }
}

.image-collection-row{
    margin: 0 auto;
}
.image-collection-image{
    margin: 10px 0;
    @include media-breakpoint-down(sm){
        width: 50%;
    }
    .img{
        height: 140px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 50% 50%;
    }
}
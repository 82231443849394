.image-text-list-block-container {
    max-width: 1570px; 
    width: 81.77vw;
    .item-container{
        margin-top: 30px; 
        margin-bottom: 150px;
        .text-container{
            .title{
                font-size: calc(20px + (30 - 20) * ((100vw - 414px) / (1920 - 414))); 
                font-family: 'brother-1816'; 
                font-weight: bold; 
                line-height: 1.2;
            }
            .text{
                font-family: 'brother-1816'; font-size: calc(16px + (18 - 16) * ((100vw - 992px) / (1920 - 992)));
                margin-top: 10px;
            }
        }
    }
    @include media-breakpoint-down(md){
        .item-container{
            margin-bottom: 100px;
            .text-container{
                margin-top: 50px;
            }
        }
    }

    @include media-breakpoint-down(sm){
        .item-container{
            .image-container{
                margin: -30px;
                &.odd{
                    transform: translateX(30px);
                }
                &.even{
                    transform: translateX(-30px);
                }
            }
            .text-container{
                padding: 0;
                .title{
                    font-size: 20px;
                    line-height: 28px;
                    margin-top: 10px;
                }
                .text{
                    font-size: 16px;
                }
            }
        }
    }
}
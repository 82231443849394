.board{
    &:nth-child(3){
        margin-top: 60px;
    }
}
.board-title{
    font-size: 40px;
    font-weight: 300;
    margin: 50px 0 20px 0;
    line-height: 52px;
    @include media-breakpoint-down(sm){
        margin: 50px 0 10px 0;
    }
}
.board-header{
    font-size: 20px;
    line-height: 30px;
    font-weight: 300;
    margin-top: 20px;
    @include media-breakpoint-down(sm){
        font-size: 18px;
        color: gray;
        margin: 10px 0;
    }
}
.has-details{
    height: 60px;
    border-bottom: 1px solid $light-gray;
    align-items: center;
    position: relative;
    &:hover{
        cursor: pointer;
    }
    @include media-breakpoint-down(sm){
        padding-left: 33px;
    }
    &.open + .details{
        max-height: 1000px;
        @include media-breakpoint-down(sm){
            flex-direction: column;
        }
    }
    &.open{
        &:before{
            content: url(../img/plus.svg);
            width: 20px;
            margin-right: 20px;
            transform: rotate(45deg);
            transition-duration: .2s;
        }
    }
    &::before{
        content: url(../img/plus.svg);
        width: 20px;
        margin-right: 20px;
        transition-duration: .2s;
        @include media-breakpoint-down(sm){
            
            position: absolute;
            left: 0;
        }
    }
}
.list-item{
    height: 60px;
    border-bottom: 1px solid $light-gray;
    align-items: center;
    position: relative;
    @include media-breakpoint-down(sm){
        border-bottom: none;
        align-items: flex-start;
        flex-direction: column;
        position: relative;
        margin: 4px 0;
    }
    .name{
        font-weight: 500;
        width: 45%;
        @include media-breakpoint-down(sm){
            width: 100%;
            line-height: 20px;
        }
    }
    .position{
        font-weight: 300;
        font-size: 16px;
        @include media-breakpoint-down(sm){
            width: 100%;
        }
    }
}
.details{
    font-size: 16px;
    line-height: 24px;
    background-color: $body-background;
    max-height: 0;
    overflow: hidden;
    transition: max-height .3s ease-in-out;
    .image-col{
        padding: 30px 20px 0 30px;
        max-width: 200px;
        @include media-breakpoint-down(sm){
            padding-left: 20px;
        }
    }
    .info-col{
        padding: 40px 20px 30px 0;
        font-weight: 300;
        @include media-breakpoint-down(sm){
            padding-left: 20px;
        }
        hr{
            background-color: $yellow;
            margin-bottom: 17px;
        }
        .name{
            font-weight: 700;
        }
        .position{
            font-weight: 300;
            font-size: 16px;
        }
        .info-line{
            padding: 3px 0;
            &:nth-of-type(5){
                margin-bottom: 40px;
            }
            .label{
                font-weight: 500;
                width: 18%;
                display: inline-block;
            }
            .info{
                display: inline;
                @include media-breakpoint-down(md){
                    display: block;
                }
            }
        }
    }
}

.board-member-list-block{
    &:hover{
        cursor: pointer;
    }
    .list-board{
        height: 60px; 
        border-top: 1px solid $light-gray;
        display: flex;
        align-items: center;
        font-weight: 500;
        position: relative;
        &.open + .board-members-list{
            max-height: 1000px;
        }
        &.open{
            &:before{
                content: url(../img/plus.svg);
                width: 20px;
                margin-right: 20px;
                transform: rotate(45deg);
                transition-duration: .2s;
            }
        }
        &::before{
            content: url(../img/plus.svg);
            width: 20px;
            margin-right: 20px;
            transition-duration: .2s;
        }
    }
    .board-members-list{
        max-height: 0;
        overflow: hidden;
        transition: max-height .3s ease-in-out;
        @include media-breakpoint-down(sm){
            margin-left: 40px;
        }
        .board-member{
            height: 60px;
            border-top: 1px solid $light-gray;
            display: flex;
            align-items: center;
            @include media-breakpoint-down(sm){
                margin: 10px 0;
                align-items: flex-start;
                border-top: none;
                flex-direction: column;
            }
            .member-name {
                font-weight: 500;
                width: 50%;
                margin-left: 45px;
                font-size: 16px;
                @include media-breakpoint-down(sm){
                    margin-left: 0;
                    width: 100%;
                }
            }
            .member-position {
                font-weight: 300;
                font-size: 16px;
            }
        }
    }
}
.fullwidth-image-block-container{
    position: relative;
    border-top: 30px solid $body-background;
    border-bottom: 30px solid $body-background;
    .text-container{
        font-size: 7.2rem;
        font-family: "brother-1816";
        height: 100%;
        width: 56%;
        position: absolute;
        left: 7%;
        top: 0;
        bottom: 0;
        width: 54%;
        font-weight: 700;
        display: flex;
        align-items: center;
        font-size: calc(26px + (72 - 26) * ((100vw - 414px) / (1920 - 414)));
        line-height: 1.1;
    }
    &:hover{
        .text-container{
            color: $white;
        }
    }
    .img-container{
        img{
            width: 100%;
            object-fit: cover;
            object-position: top right;
            max-height: 500px;
        }
    }
    @include media-breakpoint-down(xs){
        border-top: none;
        border-bottom: none;
        .text-container{
            background-color: $white;
            position: absolute;
            font-family: "brother-1816";
            font-size: 26px;
            min-height: 100px;
            padding: 20px;
            display: flex;
            align-items: center;
            font-weight: 700;
            right: 0;
            bottom: 0;
            transform: translateY(50%);
            width: 90%;
            height: auto;
            left: auto;
            top: auto;
            &:before{
                content: '';
                width: 20px;
                height: 50%;
                position: absolute;
                left: -20px;
                top: 50%;
                background-color: #F2F2F2;
            }
            &:after{
                content: '';
                height: 20px;
                width: calc(100% + 20px);
                position: absolute;
                right: 0;
                bottom: -20px;
                background-color: #f2f2f2;
            }
        }
        &:hover, :active{
            .text-container{
                color: $yellow;
            }
        }
    }
}
.paragraph-block{
    margin-bottom: 20px;
    margin-top: 10px;
    color: $gray;
    li{
        list-style-type: none;
        position: relative;
        &::before {
            content: '\2022';
            color: #E5AF23;
            display: inline-block;
            font-size: 44px;
            position: absolute;
            left: -30px;
        }
    }
    .rich-text{
        p{
            a{
                border-bottom: 2px solid $yellow;
                color: $links;
                font-weight: 500;
            }
        }
        .richtext-image{
            max-width: 100%;
            height: auto;
        }
    }
}
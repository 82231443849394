.arsskyrsla-container{
    border-top: 30px solid $body-background;
    border-bottom: 30px solid $body-background;
    min-width: 100%;
    position: relative;
    @include media-breakpoint-down(lg){
        border: none;
        margin-top: 60px;
    }
    @include media-breakpoint-down(sm){
        margin-top: 0px;
    }
    &:hover{
        .ar-subtitle{
            .inner{
                border-bottom: 1px solid $yellow !important; 
            }
        }
        svg{
            margin-left: 20px !important;
            transition: margin .4s ease-in-out !important;
        }
    }
    .arsskyrslu-block{
        max-height: 179px;
        @include media-breakpoint-down(lg){
            max-height: unset;
            overflow: hidden;
        }
        .ar-col{
            position: unset;
            @include media-breakpoint-down(lg){
                height: 350px;
            }
        }
        .content{
            height: 179px;
            .text-container{
                z-index: 1;
                @include media-breakpoint-down(xs){
                    justify-content: flex-end !important;
                }
                .ar-title{
                    font-size: 40px;
                    font-weight: 500;
                    @include media-breakpoint-down(sm){
                        font-size: 30px;
                    }
                    span{
                        font-weight: 300;
                        color: $yellow;
                    }
                }
                .subtitle-container{
                    height: 36px;
                    @media (max-width: 540px){
                        width: 100vw;
                        display: flex;
                        justify-content: center;
                    }
                }
                .ar-subtitle{
                    font-size: 16px;
                    line-height: 24px;
                    text-transform: uppercase;
                    font-weight: 500;
                    @include media-breakpoint-down(lg){
                        font-size: 14px;
                        line-height: 21px;
                        position: absolute;
                        bottom: -60px;
                        margin-left: -30px;
                    }
                    .inner{
                        display: inline-block;
                    }
                    svg{
                        width: 18px;
                        height: 16px;
                        margin-left: 12px;
                        transition: margin .4s ease-in-out;
                    }
                }
            }
            .img-container{
                z-index: 0;
                position: absolute;
                right: 0;
                bottom: -38px;
                @media (max-width: 1200px){
                    right: 0 !important;
                }
                @include media-breakpoint-down(lg){
                    overflow: hidden;
                    width: 100%;
                }
                img{
                    width: 100%;
                    height: auto;
                    object-fit: cover;
                    @include media-breakpoint-down(md){
                        width: auto;
                        height: 100%;
                    }
                    @include media-breakpoint-down(sm){
                        margin-left: -220px;
                    }
                    @include media-breakpoint-down(xs){
                        max-height: 203px;
                        margin-left: -220px;
                    }
                }
            }
        }
    }
}